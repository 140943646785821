.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

#PageTitle{
    font-size: 3em;
}

.ArchiveButton{
    height: 70px;
    width: 290px;
    margin-top: 10px;
    font-size: 2em;    
}

.hiddenElement {
    display: none;
}

.videoOverlay{
    display: inline-block;
    height: 92%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    visibility: hidden;
}
