.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

.VoteButton{
    height: 200px;
    width: 200px;
    margin: 1vw;
    font-size: 0.5em;
    --border-radius: 100%;
}

.SurveyIcon{
    font-size: 10em;
}

#SurveyBox{
    background-color: #f2f2f2;
    border-radius: 15px;
    margin: 50px;
}

.SurveyBox{
    background-color: #f2f2f2;
    border-radius: 15px;
    font-size: 2.5em;
    margin: 20px;
    padding: 20px;
}

#Alert{
    font-size: 3em;
}

#SubAlert{
    font-size: 2.5em;
}

.ButtonGood{
    --background: rgb(125, 255, 98);
}

.ButtonMedium{
    --background: rgb(250, 172, 84);
}

.ButtonBad{
    --background: rgb(218, 64, 64);
}
