.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

.DataCardsBox{
    --ion-background-color: #f2f2f2;
}

.DataCards{
    --ion-background-color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}

.CardTitle{
    font-size: 3em;
    color: rgb(66, 66, 66);
}

.bigDataCard {
    --ion-background-color: #fff;
    margin: 10px;
    padding: 10px;
    height: 94%;
    overflow: auto;
}

.bigCardTitle{
    font-size: 4em;
    color: rgb(66, 66, 66);
    margin-bottom: 3vh;
}

.ReferenceRow{
    font-size: 3em;
    color:#232F34;
}

.ReferenceRow ion-label{
    font-size: 1.1em;
    font-weight: 500;
}

.ReferenceRow ion-col{
    border-style: solid solid none solid;
}

.LastRow ion-col{
    border-bottom-style: solid;
}

.GridItem1 { grid-area: item1; }
.GridItem2 { grid-area: item2; }
.GridItem3 { grid-area: item3; }
.GridItem4 { grid-area: item4; }
.GridItem5 { grid-area: item5; }
.GridItem6 { grid-area: item6; }
.GridItem7 { grid-area: item7; }
.GridItem8 { grid-area: item8; }
.GridItem9 { grid-area: item9; }
.GridItem10 { grid-area: item10; }
.GridItem11 { grid-area: item11; }
.GridItem12 { grid-area: item12; }
.GridItem13 { grid-area: item13; }
.GridItem14 { grid-area: item14; }
.GridItem15 { grid-area: item15; }
.GridItem16 { grid-area: item16; }
.GridItem17 { grid-area: item17; }


.GridContainer {
    display: grid;
    grid-template-areas:
      'item1 item2 item2 item2 item3 item3 item3'
      'item4 item5 item6 item7 item8 item9 item10'
      'item11 item12 item13 item14 item15 item16 item17';
}

.GridContainer div{
    border-style: solid solid none solid;
    padding: 5px;
}

.GridContainer .LastRow{
    border-bottom-style: solid;
}

.ColorGridContainer {
    display: grid;
    grid-template-areas:
      'item1 item2 item3 item4 item5 item6'
      'item7 item8 item9 item10 item11 item12';
    padding: 25px 0 40px 0;
}

.ColorGridContainer div{
    border-style: none;
    margin-left: 4vw;
    height: 70px;
}

.LastColorItem{
    margin-right: 4vw;
}

.ColorGridContainer .LastRow{
    margin: 0;
    height: 100%;
}

.LeftNumber{
    margin:0;
    display: inline;
}

.RightNumber{
    margin:0;
    display: inline;
    float: right;
}

.Great{
    background-color: #70cb79;
}

.Good{
    background-color: #a6dc70;
}

.Average{
    background-color: #e6f065;
}

.Mediocre{
    background-color: #e3bb5f;
}

.Bad{
    background-color: #e08059;
}

.Terrible{
    background-color: #e44d52;
}

.BlueRow{
    background-color: #698acf;
}

.TurquoiseRow{
    background-color: #70ceb8;
}

#NoticeCard{
    --background: white;
    color: rgb(66, 66, 66);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

#NoticeCard ion-label{
    font-size: 3em;
}

#NoticeCard p{
    font-size: 2.5em;
    margin-top: 15px;
    margin-bottom: 15px;
}

#CO-Div{
    padding-top: 0.5em;
    margin-bottom: 0;
    padding-bottom: 0;
}

#CO-List{
    margin-top: 0.5em;
}

#PulsDiv{
    margin-bottom: 0.5em;
}