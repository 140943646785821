.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

.InfoText{
    font-size: 3em;
}

.InfoButton{
    font-size: 3em;
    height: 200px;
    width: 800px;
}