.MenuContent{
  height: 100%;
}

.MenuRow{
  height: 98%;
  overflow: hidden;
}

.MenuCol{
  height: 100%;
  margin-top: 10px;
}

/*.dataButton{
  height: 101%;
  padding: 3%;
  font-size: 3.7em;
  --border-radius: 15px;
}*/

.menuButton{
  --background: white;
  color: #333333;
  height: 47%;
  margin: 2%;
  padding: 1.5%;
  font-size: 3em;
  --border-radius: 15px;
}


.menuIcon{
  font-size: 2em;
  color: #f18800;
  padding-bottom: 10px;
}

.soleDataButton{
  height: 97%;
}

.modal {
  --min-width: 70vw;
}

#modaltitle {
  font-size: 3em;
  color: #424242;
  padding-bottom: 25px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

#modaltext {
  font-size: 3em;
  margin-top: -20px;
  padding-left: 3%;
  padding-right: 3%;
}

#modalbuttontext {
  font-size: 3em;
}