#ContactRow{
    padding-top: 10px;
}
#ColumnRight{
    padding: 0;
}

.BackButton{
    height: 70px;
    width: 200px;
    padding-top: 15px;
    font-size: 2em;
}

#SupportImage{
    max-height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.ContactText{
    font-size: 3em;
}

.ContactButton{
    font-size: 3em;
    height: 200px;
    width: 100%;
}

#ContactFrame{
    width: 100%;
    height: 100%;
}