/**
 * Styling for data pages:
 *  - HealthData.tsx
 *  - SecurityData.tsx
 */

.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

.CategoryButton{
    font-size: 3em;
    height: 200px;
    width: 800px;
}

#Data-2{
    margin-top: 7vh;
}

#Data-3{
    margin-top: 3vh;
}

.DataCardsBox{
    --ion-background-color: #f2f2f2;
}

.DataCards{
    --ion-background-color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    /*height: 11%;*/
    cursor: pointer;
}

.DataRow {
    margin-top: 1%;
}

.SubTitle{
    font-size: 2em;
}

.CardTitle{
    font-size: 3em;
    color: rgb(66, 66, 66);
}

.CardValue{
    font-size: 3em;
    float: right;
    color: rgb(66, 66, 66);
}

.bigDataCard {
    --ion-background-color: #fff;
    margin: 10px;
    padding: 10px;
    height: 94%;
}

.bigCardTitle{
    font-size: 4em;
    color: rgb(66, 66, 66);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bigCardValue{
    font-size: 4em;
    color: rgb(66, 66, 66);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
}

.bigCardLastWeek{
    font-size: 3.5em;
    color: rgb(66, 66, 66);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.valueLastWeek{
    font-size: 3.5em;
    color: rgb(66, 66, 66);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentText{
    font-size: 2em;
}

.Positive{
    color: rgb(50, 214, 18);
}

.Negative{
    color: rgb(214, 106, 18);
}

.sensorDataTable table, th, td {
    color: #000; /* High contrast! */
    font-size: large;

    border: 2px solid black;
    border-collapse: collapse;
    text-align: center;
}

.sensorDataTable .batteryGood {
    background-color: var(--ion-color-success-shade);

    --ion-color-base: var(--ion-color-success);
    --ion-color-base-rgb: var(--ion-color-success-rgb);
    --ion-color-contrast: var(--ion-color-success-contrast);
    --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
    --ion-color-shade: var(--ion-color-success-shade);
    --ion-color-tint: var(--ion-color-success-tint);
}

.sensorDataTable .batteryWarning {
    background-color: var(--ion-color-warning-shade);

    --ion-color-base: var(--ion-color-warning);
    --ion-color-base-rgb: var(--ion-color-warning-rgb);
    --ion-color-contrast: var(--ion-color-warning-contrast);
    --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
    --ion-color-shade: var(--ion-color-warning-shade);
    --ion-color-tint: var(--ion-color-warning-tint);
}

.sensorDataTable .batteryDanger {
    background-color: var(--ion-color-danger-shade);

    --ion-color-base: var(--ion-color-danger);
    --ion-color-base-rgb: var(--ion-color-danger-rgb);
    --ion-color-contrast: var(--ion-color-danger-contrast);
    --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
    --ion-color-shade: var(--ion-color-danger-shade);
    --ion-color-tint: var(--ion-color-danger-tint);
}
