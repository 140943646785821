.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

a{
    text-decoration:none;
}

.TrainingCardsBox{
    --ion-background-color: #f2f2f2;
}

.TrainingCards{
    margin: 50px;
    min-height: 200px;
    --ion-background-color: #fff;
    color: rgb(66, 66, 66);
}

.videopreview{
    height: 200px;
    width: 299px;
    float: left;
    margin-right: 30px;
}

.overlay{
    /*background-color: rgba(0, 0, 0, 0.2);*/
    /*background-color: rgba(255, 255, 255, 0.2);*/
    position: absolute; 
    height: 200px;
    width: 299px;
}

.playicon {
    position: absolute; 
    font-size: 80pt;
    left: 100px;
    top: 45px;
    color: rgb(240, 240, 240);
}


.SubTitle{
    font-size: 2em;
    color: rgb(66, 66, 66);
}

.CardTitle{
    font-size: 3em;
}

.ContentText{
    font-size: 2em;
}

.tec{
    --ion-background-color: #d1daec;
}

.move{
    --ion-background-color: #d2ecd1;
}

.train{
    --ion-background-color: #ecd1d1;
}

.future{
    --ion-background-color: #d1e8ed;
}

.network{
    --ion-background-color: #dbdbdb;
}

.aging{
    --ion-background-color: #fffae5;
}

.hiddenElement {
    display: none;
}