.header{
    height: 10vh;
}

.MenuContainer{
    height: 80vh;
}

.MenuContent{
    background-color: rgb(255, 218, 148);
}

.footer{
    border: none;
}

#time{
    display: inline-block;
    margin-top: 25px;
    font-size: 2em;
}

#logo{
    height: 90px;
    float: left;
}

#logoMinisterium{
    height: 80px;
    width: 80%;
    padding-top: 24px;
    padding-right: 15px;
    float: right;
}

#contactNotification{
    display: inline-block;
    padding: 10px;
    font-size: 3em;
    background-color: rgb(97, 112, 193); 
    color: white;
    position: absolute;
    top: 0.1vw;
    right: 0.1vw;
    z-index: 11;
}

#videoNotification{
    display: inline-block;
    padding: 10px;
    font-size: 3em;
    background-color: #344955; 
    color: white;
    position: absolute;
    top: 0.1vw;
    right: 0.1vw;
    z-index: 10;
}

#notificationClose{
    cursor: pointer;
}

.dataCard {
    margin-top: 15px;
    text-align: center;
}

.footerButton{
    height: 140px;
    font-size: 2.5em;
    --border-radius: 15px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
}

.helptext{
    font-size: 2.5em;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 50px;
    color: rgb(66, 66, 66);    
}