.BackButton{
    height: 70px;
    width: 200px;
    margin-top: 10px;
    font-size: 2em;
}

.DataCardsBox{
    --ion-background-color: #f2f2f2;
}

.DataCards{
    --ion-background-color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}

.CardTitle{
    font-size: 3em;
    color: rgb(66, 66, 66);
}

.bigDataCard {
    --ion-background-color: #fff;
    margin: 10px;
    padding: 10px;
    height: 94%;
    overflow: auto;
}

.bigCardTitle{
    font-size: 4em;
    color: rgb(66, 66, 66);
    margin-bottom: 3vh;
}

.ReferenceRow{
    font-size: 3em;
    color:#232F34;
}

.ReferenceRow ion-label{
    font-size: 1.1em;
    font-weight: 500;
}

.ReferenceRow p{
    margin-top:15px;
    margin-bottom:35px;
    line-height: 145%;
}

#NoticeCard{
    --background: white;
    color: rgb(66, 66, 66);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

#NoticeCard ion-label{
    font-size: 3em;
}

#NoticeCard p{
    font-size: 2.5em;
    margin-top: 15px;
    margin-bottom: 15px;
}


